<template>
    <div class="head-bar">
        <div class="logo-box content">
            <div class="logo" @click="gotoIndex">
                <img src="@assets/big_logo.svg" style="width: 268px;"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginHeader",
    methods:{
        gotoIndex(){
            this.$router.push({name:'Index', path: '/index'})
        }
    }
}
</script>

<style scoped>
.head-bar{
    width: 100%;
    background: #fff;
    height: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.logo{
    cursor: pointer;
}
.site-name{
    font-family: SourceHanSansCN-Medium;
    font-size: 12px;
    color: #2F86C2;
    letter-spacing: 0;
    text-align: justify;
    margin-left: 12px;
}
</style>
