<template>
    <div class="login">
        <LoginHead></LoginHead>
        <div class="login-box">
            <div class="login-form">
                <div class="title">
                    广东省科技资源共享服务平台
                </div>
                <div class="segment">
                    <div class="segment-item" :class="{active:active==0}" @click="onChange(0)">密码登录</div>
                    <div class="segment-item" :class="{active:active==1}" @click="onChange(1)">短信登录</div>
                    <div class="segment-item" :class="{active:active==2}" @click="onChange(2)" v-show="false">扫码登录</div>
                </div>
                <div class="form-control username" v-if="active==0">
                    <img src="@static/img/user.svg" class="input-icon"/>
                    <input autocomplete="new-password" type="text" v-model="account"
                           class="form-control-addon login-input" placeholder="用户名/邮箱" id="username"/>
                </div>
                <div class="form-control password" v-if="active==0">
                    <img src="@static/img/lock.svg" class="input-icon"/>
                    <input autocomplete="new-password" type="password" v-model="password"
                           class="form-control-addon login-input" placeholder="密码" id="password"
                           @keydown.enter="login"/>
                </div>
                <div class="remember" v-if="active==0">
                    <label class="checkbox"><input type="checkbox" v-model="isRemember">记住我</label>
                </div>


                <div class="form-control phone" v-if="active==1">
                    <img src="@static/img/phone.svg" class="input-icon"/>
                    <input autocomplete="new-password" type="text" v-model="phone"
                           class="form-control-addon login-input" placeholder="手机号码" id="phone"/>
                </div>

                <div class="phone-code" v-if="active==1">
                    <div class="form-control phone">
                        <img src="@static/img/lock.svg" class="input-icon"/>
                        <input autocomplete="new-password" type="text" v-model="vsCode"
                               class="form-control-addon login-input" placeholder="短信验证码" id="phone-code"/>
                    </div>
                    <button type="button" class="btn btn-primary btn-send" :class="{'disabled':showCount}"
                            @click="initImgCode" v-loading="codeLoading" element-loading-background="rgba(0,0,0,0.7)">
                        {{ showCount ? count : '发送验证码' }}
                    </button>

                </div>

                <button type="button" v-if="active==0||active==1" class="btn btn-primary login-btn" @click="login"
                        v-loading="loginLoading" element-loading-background="rgba(0,0,0,0.7)">登录
                </button>

                <div class="qrcode" v-if="active==2">
                    <img src="@static/img/qrcode.svg"/>
                </div>

                <div class="func">
                    <a href="javascript:void(0)" @click="phoneRegister">手机注册</a>
                    <span>|</span>
                    <!--
                    <a href="javascript:void(0)" @click="emailRegister">邮箱注册</a>
                    <span>|</span>-->
                    <a href="javascript:void(0)" @click="forgetPwd">忘记密码</a>

                </div>

                <!--                <div class="support">技术支持电话：13632332125</div>-->
            </div>

        </div>

        <div class="copyright">版权所有：广东省科技基础条件平台中心</div>

        <el-dialog v-model="showDialog" title="验证码" width="300px" @close='closeDialog'>
            <div @click="reloadImgCode" style="width: 100%;display: flex;justify-content: center;">
                <img style="width:300px;" :src="changeImgUrl()" alt="验证码"/>
            </div>
            <div style="display: flex;justify-content: center;padding-top: 20px;">
                <input type="text" class="form-control" v-model="imgCode" placeholder="验证码" @keydown.enter="login"/>
                <button style="margin-left: 10px;" type="button" class="btn btn-primary btn-search" @click="sendCode">
                    确定
                </button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import LoginHead from '../components/common/LoginHead'
import md5 from 'js-md5'
import {ref, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
import CommonUtil from "@/utils/common"

export default {
    data() {
        return {
            active: 0
        }
    },
    components: {LoginHead},
    setup() {
        const imgCode = ref('');
        const imgUrl = ref('');
        const showDialog = ref(false)
        const {proxy} = getCurrentInstance();

        const loginLoading = ref(false);
        const codeLoading = ref(false);
        const count = ref(60);
        const showCount = ref(false);
        const account = ref(proxy.$store.state.account);
        const password = ref('');
        const phone = ref('');
        const vsCode = ref('');
        const isRemember = ref(account.value ? true : false)

        let timer = undefined;
        let imgCodeTimer = undefined;
        const route = useRoute();
        const redirect = ref('');
        const reffer = ref('');
        if (route.query.redirect) {
            redirect.value = route.query.redirect;
        }
        if (route.query.reffer) {
            reffer.value = route.query.reffer;
        }


        function login() {

            showCount.value = false;
            let params;
            let url;
            if (proxy.active == 0) {
                if (!account.value) {
                    proxy.ElMessage.error("请输入账号");
                    return;
                }
                if (!password.value) {
                    proxy.ElMessage.error("请输入密码");
                    return;
                }
                params = {userCode: account.value, userPwd: md5(password.value)};
                url = '/gdsti2/web/a/web/webLogin?loginType=user_code';
            } else if (proxy.active == 1) {
                if (!phone.value || (phone.value + "").length != 11) {
                    proxy.ElMessage.error("手机号码长度不对");
                    return;
                }
                if (!vsCode.value) {
                    proxy.ElMessage.error("请输入验证码");
                    return;

                }
                params = {phone: phone.value, vsCode: vsCode.value};
                url = '/gdsti2/web/a/web/webLogin?loginType=mobile_phone';
            }

            loginLoading.value = true;

            proxy.$http.post(url, params)
                .then(res => {
                    if (isRemember.value) {
                        proxy.$store.commit('updateAccount', {account: account.value});
                    } else {
                        proxy.$store.commit('updateAccount', {account: ""});
                    }

                    if (res.data.state == 0) {
                        //返回用户信息
                        const userInfo = res.data.result;

                        proxy.$store.commit('updateUserInfo', {userInfo: userInfo});

                        //跳转到首页
                        proxy.$router.push({name: 'Index', path: '/index'});
                    } else {
                        loginLoading.value = false;
                    }
                }).catch(err => {
                loginLoading.value = false;
            })
        };

        function sendCode() {

            // console.log('sendCode')
            if (showCount.value) {
                return;
            }
            if (timer) {
                clearInterval(timer);
            }

            if (!phone.value || (phone.value + "").length != 11) {
                proxy.ElMessage.error("手机号码长度不对");
                return;
            }
            codeLoading.value = true;

            const url = '/gdsti2/web/a/web/sendVCodeMsg';
            proxy.$http.post(url, {phone: phone.value, randomCode: imgCode.value, relateType: 'login'}).then(res => {
                codeLoading.value = false;
                if (res.data.state == 0) {
                    showDialog.value = false
                    clearInterval(imgCodeTimer);
                    showCount.value = true;
                    count.value = 300;
                    timer = setInterval(function () {
                        count.value = count.value - 1;
                        if (count.value <= 0) {
                            codeLoading.value = false;
                            clearInterval(timer);
                            timer = undefined;
                            showCount.value = false;
                        }
                    }, 1000);
                } else {
                    // proxy.ElMessage.error(res.data.message)
                    showCount.value = false;
                }
            }).catch(err => {
                codeLoading.value = false;
                showCount.value = false;
            })

        };

        // 初始化图形验证码
        function initImgCode() {
            this.showDialog = true
            this.imgCode = ""
            this.imgUrl = "/core//base/c/index/randomcode?time=" + new Date().getTime()
            imgCodeTimer = setInterval(() => {
                // console.log("定时器");
                this.reloadImgCode();
            }, 15000)
        };

        //关闭dialog时，清空定时器
        function closeDialog() {
            clearInterval(imgCodeTimer);
        };

        // 刷新图形验证码
        function reloadImgCode() {
            this.imgCode = ""
            this.imgUrl = "/core//base/c/index/randomCode?time=" + new Date().getTime()
            // console.log(this.imgUrl);
        }


        return {
            vsCode, phone, isRemember, showDialog, imgCode, imgUrl,
            loginLoading, codeLoading, account, password, showCount, count, login, sendCode, initImgCode, closeDialog,
            reloadImgCode, imgCodeTimer
        }
    },

    methods: {
        forgetPwd() {
            this.$router.push({name: 'ForgetPwd', path: '/forgetPwd'});
        },
        phoneRegister() {
            this.$router.push({name: 'Register', path: 'Register', query: {type: 1}});
        },
        emailRegister() {
            this.$router.push({name: 'Register', path: 'Register', query: {type: 0}});
        },
        onChange: function (index) {
            this.active = index;
        },
        changeImgUrl: function () {
            return this.imgUrl;
        },


    },
    beforeUnmount() {
        clearInterval(this.imgCodeTimer);
    }
}
</script>

<style lang="less" scoped>
@import url(../../public/static/css/login.less);

.login-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 390px;
    height: 370px;
    background: #FFFFFF;
    padding: 20px 30px;

    .title {
        font-family: SourceHanSansCN-Medium;
        font-size: 24px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
    }

    .segment {
        display: flex;
        justify-content: space-evenly;
        margin-top: 35px;
        margin-bottom: 35px;

        .segment-item {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
            cursor: pointer;
            padding-left: 12px;
            padding-bottom: 4px;
            padding-right: 12px;
        }

        .segment-item.active {
            color: #007EEC;
            border-bottom: 2px solid #007EEC;
        }
    }
}

.form-control {
    margin: 0;
    display: flex;
    align-items: center;
}

.login-input {
    width: 240px;
}

.qrcode {
    text-align: center;

    img {
        height: 180px;
        width: 180px;
    }
}

.password, .phone-code {
    margin-top: 22px;
    margin-bottom: 14px;
}

.phone-code {
    display: flex;
    justify-content: space-between;

    .login-input {
        width: 196px;
    }

    .btn-send {
        width: 110px;
    }
}

.remember {
    text-align: right;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.50);
    display: flex;
    justify-content: flex-end;
    line-height: 12px;

    .checkbox {
        cursor: pointer;
        display: flex;
        align-items: center;

        input {
            margin-right: 4px;
        }
    }
}

.login-btn {
    width: 100%;
    margin-top: 20px;
    height: 40px;
    border-radius: 6px;
}

.func {
    text-align: center;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #007EEC;
    margin-top: 19px;
    line-height: 12px;

    a {
        color: #007EEC;
    }

    span {
        margin-right: 4px;
        margin-left: 4px;
    }

}

.copyright {
    text-align: center;
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.50);
    position: fixed;
    bottom: 18px;
    width: 100%;
}

.support {
    text-align: center;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.50);
    margin: 14px;
}

.login {
    height: 100%;
    background: #F6F6F6;
}

</style>

