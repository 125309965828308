import axios from 'axios'
import qs from 'qs'


const kb = 1024;
const mb = 1024 * kb;
const gb = 1024 * mb;

const faIcons = {
    'pdf': 'fa-file-pdf-o',
    'png': 'fa-file-photo-o',
    'bmp': 'fa-file-photo-o',
    'gif': 'fa-file-photo-o',
    'jpg': 'fa-file-photo-o',
    'jpeg': 'fa-file-photo-o',
    'xls': 'fa-file-excel-o',
    'xlsx': 'fa-file-excel-o',
    'doc': 'fa-file-word-o',
    'docx': 'fa-file-word-o',
    'ppt': 'fa-file-powerpoint-o',
    'pptx': 'fa-file-powerpoint-o',
    'zip': 'fa-file-zip-o',
    'rar': 'fa-file-zip-o',
    '7z': 'fa-file-zip-o',
    'mp3': 'fa-file-sound-o',
    'mav': 'fa-file-sound-o',
    'mp4': 'fa-file-movie-o',
    '3gp': 'fa-file-movie-o',
    'txt': 'fa-file-text-o'

}
export default {


    /**
     * 获取用户信息
     * {
            "contractAddr": "GuangZhou",
            "mobilePhone": "18926171600",
            "supervisorType": 0,
            "emailAddr": "ting.chen@hong-feng.com",
            "sex": 0,
            "isAdmin": 1,
            "userName": "超级管理员",
            "userCode": "admin",
            "status": 0
        }
     */
    getUserInfoFromLocal() {
        let userInfoStr = localStorage.getItem('userInfo');

        return JSON.parse(userInfoStr);
    },
    setEmpty(obj) {
        Object.keys(obj).forEach((key) => {
            obj[key] = "";
        })
    },
    toFileSize(size) {
        if (size) {
            if (size >= gb) {
                return (size / gb).toFixed(2) + " GB";
            } else if (size >= mb) {
                return (size / mb).toFixed(2) + " MB";
            } else if (size >= kb) {
                return (size / kb).toFixed(2) + " KB";
            } else {
                return size + "B";
            }
        }
    },
    toFileIcon(fileName) {
        if (!fileName) {
            return 'fa-file'
        }
        let ext = "";
        let index = fileName.lastIndexOf('.');
        if (index >= 0) {
            ext = fileName.substring(index + 1);
            let icon = faIcons[ext];
            if (icon) {
                return icon;
            } else {
                return 'fa-file'
            }
        } else {
            return 'fa-file'
        }
    },
    leftPad(str, len) {
        return new Array(len - (str + '').length + 1).join('0') + str;
    },
    getUpdateTime() {
        //每分钟更新数据
        return 60 * 1000
    },
    isBlank(param) {
        if (param === null || param === undefined || param === '') {
            return true
        } else {
            return false
        }
    },
    getImage(attachId) {
        return `/core/base/c/download/image?attachId=${attachId}`
    },
    async isLogin() { //是否已登录

        let result = false;
        let url = '/core/base/a/user/isLogin';

        const res = await axios.post(url);
        // console.log('isLogin:', res);
        if (res) {
            if (res.data.state == 0 && res.data.result === true) {
                result = true;
            }
        }

        return result;
    },
    async isPlatformAdmin() { //是否平台管理员

        let result = false;
        let url = '/gdsti2/web/a/web/hasRole';


        // const res = await axios.post(url, qs.stringify({roleCode:'PlatformAdmin'}));
        const res = await axios.post(url, {roleCode: 'workplaceProjectAdmin'});
        // console.log('isLogin:', res);
        if (res) {
            if (res.data.state == 0 && res.data.result === true) {
                result = true;
            }
        }

        return result;
    },
    async getUserInfo() { //获取当前登录用户信息

        let userInfo = null;
        let url = '/core/base/a/user/getUsersByUserCodeOne';

        const res = await axios.post(url);
        if (res) {
            if (res.data.state == 0) {
                userInfo = res.data.result;
            }
        }

        return userInfo;
    },
    async getUnitInfo() { //获取当前登录用户依托单位信息

        let unitInfo = null;
        let url = '/gdsti2/lab/c/labUnit/getUserUnitId';

        const res = await axios.post(url);
        if (res) {
            if (res.data.state == 0) {
                unitInfo = res.data.result;
            }
        }

        return unitInfo;
    }
}
